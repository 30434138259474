<template>
	<v-container id="error-view" class="fill-height text-center" tag="section">
		<v-row justify="center" align="center" class="fill-height">

			<v-col cols="12" sm="8" md="8" lg="6" xl="4">
				<v-card elevation="0" icon="mdi-logout" icon-small title="" color="transparent">
					<h3>You have been logged out</h3>
					<v-btn color="accent" class="mt-5" :to="{name:'Login'}">Log in</v-btn>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import {call, get} from "vuex-pathify";

export default {
	name: 'Logout',
	metaInfo: {
		title: 'Logout',
	},
	computed: {
		user: get('user/user'),
		token: get('user/token')
	},
	beforeMount() {
		this.logout(null)
	},
	methods: {
		logout: call('user/logout')
	}
}
</script>